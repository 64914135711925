export enum Redirect {
  USER_RESEARCH = "user_research",
  NATIVE_APP = "native_app",
  WEBAPP_HOME = "webapp",
  CHECKOUT = "checkout",
  PRICING = "pricing",
  THANK_YOU = "thank_you",
  PRICING_INDIVIDUAL = "pricing-individual",
  PRICING_COUPLE = "pricing-couple",
  REGISTRATION = "registration",
}
