export type Redirects = { [key: string]: string };

const PRICING_DOMAIN = process.env.REACT_APP_PRICING_DOMAIN;
const NATIVE_APP_DOMAIN = process.env.REACT_APP_NATIVE_APP_DOMAIN;
const WEBAPP_DOMAIN = process.env.REACT_APP_WEBAPP_DOMAIN;
const CHECKOUT_LAMBDA = process.env.REACT_APP_CHECKOUT_LAMBDA;
const REACT_APP_RITUAL_DOMAIN = process.env.REACT_APP_RITUAL_DOMAIN;

const PRICING_DEV_DOMAIN = process.env.REACT_APP_PRICING_DEV_DOMAIN;
const NATIVE_APP_DEV_DOMAIN = process.env.REACT_APP_NATIVE_APP_DEV_DOMAIN;
const WEBAPP_DEV_DOMAIN = process.env.REACT_APP_WEBAPP_DEV_DOMAIN;
const CHECKOUT_DEV_LAMBDA = process.env.REACT_APP_CHECKOUT_DEV_LAMBDA;

export const prodRedirects: Redirects = {
  pricing: PRICING_DOMAIN || "",
  "pricing-individual": `${PRICING_DOMAIN}/select-plan-individual`,
  "pricing-couple": `${PRICING_DOMAIN}/select-plan-couple`,
  checkout: CHECKOUT_LAMBDA || "",
  native_app: NATIVE_APP_DOMAIN || "",
  webapp: `${WEBAPP_DOMAIN}/signup`,
  user_research: "https://survey.typeform.com/to/o5rmlbSY",
  thank_you: `${REACT_APP_RITUAL_DOMAIN}/ritual-thank-you`,
  registration: `${process.env.REACT_APP_REGISTRATION_DOMAIN}/signup`,
};

export const devRedirects: Redirects = {
  pricing: PRICING_DEV_DOMAIN || "",
  "pricing-individual": `${PRICING_DEV_DOMAIN}/select-plan-individual`,
  "pricing-couple": `${PRICING_DEV_DOMAIN}/select-plan-couple`,
  checkout: CHECKOUT_DEV_LAMBDA || "",
  native_app: NATIVE_APP_DEV_DOMAIN || "",
  webapp: `${WEBAPP_DEV_DOMAIN}/signup`,
  user_research: "https://survey.typeform.com/to/o5rmlbSY",
  thank_you: `${REACT_APP_RITUAL_DOMAIN}/ritual-thank-you`,
  registration: `${process.env.REACT_APP_REGISTRATION_DOMAIN}/signup`,
};
